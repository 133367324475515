import BaseModel from './BaseModel';
import Place from './Place';
import Bucket from './Bucket';
import Pricing from './Pricing';
import Ref from './Ref';
import _ from 'lodash';
import moment from 'moment';

/** Class representing a screening. */
class Screening extends BaseModel {
    constructor(properties) {
        super(properties);

        if (this.pricings)
            _.mapKeys(this.pricings, (pricing, key) => {
                this.pricings[key] = new Pricing(pricing);
            });

        this.buckets = (this.buckets || []).map(bucket => new Bucket(bucket));

        if (this.start_at)
            this.start_at = moment(this.start_at);
        if (this.stop_at)
            this.stop_at = moment(this.stop_at);

        if (this.cinema_hall)
            this.cinema_hall = new Place(this.cinema_hall);

        this.refs = (this.refs || []).map(ref => new Ref(ref));

        // ensure sane defaults for opaque properties
        this.opaque                    = this.opaque || {};
        this.opaque._3d                = !!this.opaque._3d;
        this.opaque.break              = !!this.opaque.break;
        this.opaque.map_only_bookings  = !!this.opaque.map_only_bookings;
        this.opaque.ignore_on_maccsbox = !!this.opaque.ignore_on_maccsbox;
    }

    /**
     * Prepare this object for update.
     * This is used to "normalize", if needed, some properties
     * before to send them.
     *
     * return{BaseModel}
     */
    prepareForUpdate() {
        const prepared = super.prepareForUpdate();
        if (_.values(prepared.description).length === 0)
            delete prepared.description;

        if (prepared.start_at && moment(prepared.start_at).isBefore(moment())) {
            delete prepared.start_at;
            delete prepared.stop_at;
        }

        if (prepared.cinema_hall)
            prepared.cinema_hall = prepared.cinema_hall.prepareForUpdate();

        return prepared;
    }

    getTotalConfirmedBookings = () => {
        return _.reduce(this.buckets, (b, memo) => {
            return memo  + b.confirmed;
        }, 0)
    }
}

/**
 * Get the fields to get server side when we
 * ask for a listing
 */
Screening.getListingFields = () => [
    '_id', 'title', 'cinema_hall.cinema', 'cinema_hall.name',
    'start_at', 'stop_at', 'buckets', 'opaque.posters'
];

export default Screening;
