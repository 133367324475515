import store from '..';
import { Tickets, Utils } from '../../services';
import { createRestSlices, applyReducers } from './rest';

const {
    initialState,
    getReducer, listReducer, updateReducer, duplicateReducer,
    startCreateReducer, createReducer, deleteReducer,
    getAction, listAction, updateAction, duplicateAction,
    startCreateAction, createAction, deleteAction
} = createRestSlices(Tickets);

initialState.filters = {};

const UPDATING_FILTERS = 'kronos/tickets/UPDATING_FILTERS';

const reducer = (state, action) => {
    switch (action.type) {
        case UPDATING_FILTERS:
            return {
                ...state,
                filters: action.filters,
            };
        default:
            return state;
    }
};

/* Export reducer */
export default (state = initialState, action) => {
    return applyReducers(state, action, [
        getReducer, listReducer, updateReducer, duplicateReducer,
        startCreateReducer, createReducer, deleteReducer,
        reducer
    ]);
}

/* Export CRUD actions */
export const startCreateTicket = startCreateAction;
export const createTicket      = createAction;
export const loadTicket        = getAction;
export const loadTickets       = listAction;
export const updateTicket      = updateAction;
export const deleteTicket      = deleteAction;
export const duplicateTicket   = duplicateAction;

export function updateTicketsFilters(filters) {
    return (dispatch) => {
        dispatch({ type: UPDATING_FILTERS, filters });
    }
}
