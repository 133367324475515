import React from 'react';
import { useTranslation } from 'react-i18next';
import { Utils } from '../../services';
import SelectField from '../SelectField/SelectField';
import MultiSelectField from '../MultiSelectField/MultiSelectField';

import './TicketStatusField.css';

const TicketStatusField = ({ name, placeholder, single }) => {
    const { t }   = useTranslation();
    const options = Utils.ticketStatus().map(s => {
        return {
            key: s,
            text: t(`tickets.status.${s}`),
            value: s,
        };
    });

    return single ? (
        <SelectField
            name={ name }
            options={ options }
            placeholder={ placeholder }
            clearable
        />
    ) : (
        <MultiSelectField name={ name } options={ options } placeholder={ placeholder } clearable />
    );
}

export default TicketStatusField;
