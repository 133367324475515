import React from 'react';
import { Utils } from '../../services';
import SelectField from '../SelectField/SelectField';
import MultiSelectField from '../MultiSelectField/MultiSelectField';
import i18n from '../../locales/i18n';

import './ScreeningsField.css';

const ScreeningsField = ({ name, screenings, placeholder, single }) => {
    const options = (screenings || []).map(screening => {
        return {
            key: screening._id,
            text: [
                Utils.localized_or_fallback(screening.title, i18n.language).substring(0, 20),
                screening.start_at.format('LLL'),
                screening.stop_at.format('LLL')
            ].join(' - '),
            value: screening._id
        };
    });

    return single ? (
        <SelectField
            name={ name }
            options={ options }
            placeholder={ placeholder }
            clearable
            searchable="true"
        />
    ) : (
        <MultiSelectField name={ name } options={ options } placeholder={ placeholder } clearable />
    );
}

export default ScreeningsField;
