import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import i18n from '../../../../locales/i18n';
import { Utils } from '../../../../services';
import moment from 'moment';
import _ from 'lodash';
import { Button, Col, Form, Input, InputGroup, InputGroupAddon, Row } from 'reactstrap';
import { ExcelExportButton, Table } from '../../../../components';
import matchSorter from 'match-sorter';

const ScreeningFormBookings = ({ tickets, screeningId, setting }) => {
    const { t }               = useTranslation();
    const [filter, setFilter] = useState('');

    const nbBookings = _.flatten(tickets?.map(ticket => ticket.bookings?.filter(b => !!b.confirmed_at && b.screening?._id === screeningId))).length;

    const data = useMemo(() => {
        let data       = [];
        const currency = Utils.currency();

        tickets.forEach(ticket => {
            const isOneTimePass = ticket.type?._id === "one-time-pass";
            const bookings = ticket.bookings?.filter(b => !!b.confirmed_at && b.screening?._id === screeningId) || [];

            data = data.concat(bookings.map((booking) => ({
                type: isOneTimePass ? t("screenings.edit.form.ticket.one_time_pass") : ticket.type?.name[i18n.language] ?? ticket.type.name[Object.keys(ticket.type.name)[0]],
                pricing: ticket.activated_pricing.name[i18n.language],
                value: booking.value ? booking.value[currency] : (isOneTimePass ? ticket.activated_pricing?.value[currency] : ticket.activated_pricing.value_per_screening[currency]),
                booking_date: moment(booking.confirmed_at).format("lll"),
                status: t("screenings.edit.form.ticket." + ticket.status),
                name: (ticket.contact?.firstname && ticket.contact.lastname) ? [ticket.contact.firstname, ticket.contact.lastname].join(' ').trim() : ticket.contact.firstname ?? ticket.contact.lastname ?? "",
                email: ticket.contact?.email ?? "",
                tel: ticket.contact?.phone ?? ticket.contact.cellphone ?? "",
                seats: booking.seat ?? "n/a",
                scanned: booking.scanned_at?.length > 0 ? 1 : 0,
                in_room: booking._in ? 1 : 0,
                id: booking._id,
                ticketId: ticket._id
            })));
        });

        return matchSorter(data, filter, { keys: ['type', 'pricing', 'status', 'name', 'email', 'tel'] });
    }, [tickets, filter]);

    const toExcelData = data => {
        if (!data)
            return [];

        return data.map(row => {
            const excelRow = {};
            Object.keys(row).map(key => {
                if (key === 'scanned')
                    excelRow[t(`screenings.edit.form.ticket.scanned`)] = row.scanned > 0 ? t('common.yes') : t('common.no');
                else if (key === 'in_room')
                    excelRow[t(`screenings.edit.form.ticket.in_room`)] = row.in_room > 0 ? t('common.yes') : t('common.no');
                else
                    excelRow[t(`screenings.edit.form.ticket.${key}`)] = row[key];
            });
            return excelRow;
        });
    };

    const ticketColumns = useMemo(() => [
        {
            Header: <b>{t("screenings.edit.form.ticket.type")}</b>,
            filterable: false,
            accessor: "type",
            width: 230,
            Cell: (row => <a className='link-pass' href={setting?.eshop?.uri + "/ticket/view/?uuid=" + data[row.index].ticketId} target="_blank"><u><b>{row.value}</b></u>&nbsp;<i className="fa fa-external-link"></i></a>),
            Footer: (row => <b>{t('statstypes.total')}</b>)
        },
        {
            Header: <b>{t("screenings.edit.form.ticket.pricing")}</b>,
            filterable: false,
            className: "align-center-table",
            accessor: "pricing"
        },
        {
            Header: <b>{t("screenings.edit.form.ticket.value")}</b>,
            filterable: false,
            className: "align-center-table",
            accessor: "value",
            Cell: (row => row.value + " " + Utils.currency()),
            Footer: (row => data ? <b>{_.sum((data).map(v => v.value)) + " " + Utils.currency()}</b> : "")
        },
        {
            Header: <b>{t("screenings.edit.form.ticket.booking_date")}</b>,
            filterable: false,
            accessor: "booking_date",
        },
        {
            Header: <b>{t("screenings.edit.form.ticket.status")}</b>,
            filterable: false,
            className: "align-center-table",
            accessor: "status"
        },
        {
            Header: <b>{t("screenings.edit.form.ticket.name")}</b>,
            filterable: false,
            accessor: "name"
        },
        {
            Header: <b>{t("screenings.edit.form.ticket.email")}</b>,
            filterable: false,
            width: 180,
            accessor: "email"
        },
        {
            Header: <b>{t("screenings.edit.form.ticket.tel")}</b>,
            filterable: false,
            accessor: "tel"
        },
        {
            Header: <b>{t("screenings.edit.form.ticket.seats")}</b>,
            filterable: false,
            className: "align-center-table",
            accessor: "seats"
        },
        {
            Header: <b>{t("screenings.edit.form.ticket.scanned")}</b>,
            filterable: false,
            className: "align-center-table",
            width: 70,
            accessor: "scanned",
            Cell: (row => row.value > 0 ? <i className="fa fa-check"></i> : <i className="fa fa-times"></i>),
            Footer: (row => data ? <b>{_.sum((data).map(v => v.scanned))}</b> : "")
        },
        {
            Header: <b>{t("screenings.edit.form.ticket.in_room")}</b>,
            filterable: false,
            className: "align-center-table",
            accessor: "in_room",
            width: 70,
            Cell: (row => row.value > 0 ? <i className="fa fa-check"></i> : <i className="fa fa-times"></i>),
            Footer: (row => data ? <b>{_.sum((data).map(v => v.in_room))}</b> : "")
        },
    ], [tickets, setting, data])

    return (
        <div>
            <Form>
                <Row>
                    <Col className='col-sm-12 col-md-6'>
                        <div className="d-flex">
                            <h4>
                                {t("screenings.edit.form.ticket.nb_bookings", {nb: nbBookings})}
                            </h4>
                        </div>
                    </Col>
                    <Col className='col-sm-12 col-md-6'>
                        <div className="d-flex">
                            <Input placeholder={t('statstypes.form.search') + "..."} onChange={(e) => setFilter(e.target.value)} />
                            <div className="d-flex ml-2 w-50">
                                <ExcelExportButton csvData={toExcelData(data)} fileName="bookings" className="btn-block" />
                            </div>
                        </div>
                    </Col>
                </Row>
            </Form>
            {data && ticketColumns &&
                <Table
                    data={data}
                    columns={ticketColumns}
                    showPagination={data.length > 10}
                    collapseOnDataChange
                    id="screeningTickets"
                    filterable={false}
                />
            }
        </div>
    )
}

export default ScreeningFormBookings;
