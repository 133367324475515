import React from 'react';
import { useSelector } from 'react-redux';
import { Route } from 'react-router-dom';
import TicketsList from './TicketsList/TicketsList';

const Tickets = ({ match }) => {
    const { isLoading, hasLoadingError, loadingErrorMessage } = useSelector(state => state.tickets);

    return (
        <div className="Tickets">

            {isLoading &&
                <span className="loadingMessage"><i className="fa fa-spinner fa-spin"></i>&nbsp;Chargement en cours...</span>
            }
            {hasLoadingError &&
                <div
                    className="alert alert-danger"
                    dangerouslySetInnerHTML={{ __html: loadingErrorMessage }} />
            }
            <Route exact path={match.path} component={TicketsList} />
        </div>
    );
}

export default Tickets;
