import React, { useEffect, useMemo } from 'react';
import moment from 'moment'
import { useDispatch, useSelector } from 'react-redux'
import { Button } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import { loadScreenings, loadTickettypes, loadEmailLayouts, loadTickets, updateTicketsFilters } from '../../../redux';
import { CopyButton, PageTitle, Table, EventTitle, TableButtons, CreateButton } from '../../../components';
import { Filters, Utils } from '../../../services';
import { Tickettype } from '../../../models';
import TicketsFilters from './TicketFilters/TicketsFilters';
import _ from 'lodash';

import 'react-table-6/react-table.css'
import './TicketsList.css';

const TicketsList = ({ }) => {
    const dispatch    = useDispatch();
    const { t, i18n } = useTranslation();

    const { setting }                     = useSelector(state => state.settings);
    const { tickets, isLoading, filters } = useSelector(state => state.tickets)
    const emails                          = useSelector(state => state.emailLayouts.layouts)
    const { screenings }                  = useSelector(state => state.screenings)
    const { tickettypes }                 = useSelector(state => state.tickettypes)
    const { isMobile }                    = useSelector(state => state.ui);

    moment.locale(i18n.language);

    const preparedTickets = React.useMemo(() => {
        return tickets?.map(ticket => {
            const { firstname, lastname, email } = (ticket.contact || {});
            const prepared = {
                _id: ticket._id,
                status: ticket.status,
                contact: { firstname, lastname, email },
                vdr_auth_serial: ticket.vdr_auth_serial,
                vdr_auth_key: ticket.vdr_auth_key,
                nbookings: ticket.bookings?.length || 0
            };
            prepared.owner = [ firstname, lastname, email].join(' ');

            const tickettype = tickettypes?.find(t => t._id === ticket.type?._id);
            prepared.type_name = tickettype ?
                Utils.localized_or_fallback(tickettype.name, i18n.lang) : '?';

            prepared.activated_pricing_name = ticket.activated_pricing ?
                Utils.localized_or_fallback(ticket.activated_pricing.name, i18n.lang) : '-';

            return prepared;
        });
    }, [ tickets, i18n.lang, tickettypes ]);

    const submit = (values) => {
        values = _.cloneDeep(values || {});
        values.without_one_time_pass = !values?.types?.includes(Tickettype.ONE_TIME_PASS);

        dispatch(updateTicketsFilters(values));
        dispatch(loadTickets(values));
    }

    useEffect(() => {
        dispatch(loadScreenings({
           start_at_gte: moment().toDate()
        }));
        dispatch(loadTickettypes());
        if (filters.search || filters.types?.length)
            dispatch(loadTickets(filters));
        dispatch(loadEmailLayouts());
    }, [dispatch])

    const title =
        <span>{t("tickets.ticket_plural")}</span>

    const columns = [{
        Header: t("tickets.columns._id"),
        filterAll: true,
        accessor: '_id',
        filterMethod: Filters.textFilterMethod(['_id', 'vdr_auth_serial']),
        Filter: Filters.textFilter,
        Cell: cell => (
            <div className="d-flex gap-1">
                <CopyButton
                    badge
                    size="xs"
                    color="info"
                    title={ 'ID: ' + cell.value.substring(0, 8) + '...' }
                    textToCopy={ cell.value }
                    className="inline"
                />
                {cell.original.vdr_auth_serial && (
                    <CopyButton
                        badge
                        size="xs"
                        color="dark"
                        title={ 'TicketID: ' + cell.original.vdr_auth_serial }
                        textToCopy={ cell.original.vdr_auth_serial + '-' + cell.original.vdr_auth_key }
                        className="inline"
                    />
                )}
            </div>
        )
    }, {
        Header: t("tickets.columns.owner"),
        filterAll: true,
        accessor: 'owner',
        filterMethod: Filters.textFilterMethod('owner'),
        Filter: Filters.textFilter,
        Cell: cell => {
            const { contact } = cell.original;
            return (
            <>
                <b>
                    {[contact.lastname, contact.firstname].filter(Boolean).join(' ')}
                </b>
                <div>
                    <small>{ contact.email }</small>
                </div>
            </>
            )
        }
    }, {
        Header: t("tickets.columns.status"),
        filterAll: true,
        accessor: 'status',
        Filter: Filters.ticketStatusFilter(),
        filterMethod: Filters.textFilterMethod('status'),
        Cell: (cell, row) => t(`tickets.status.${cell.value}`)
    }, {
        Header: t("tickets.columns.type"),
        filterAll: true,
        accessor: 'type_name',
        Filter: Filters.textFilter,
    }, {
        Header: t("tickets.columns.activated_pricing"),
        filterAll: true,
        accessor: 'activated_pricing_name',
        filterMethod: Filters.textFilterMethod(['activated_pricing_name']),
        Filter: Filters.textFilter,
    }, {
        Header: t("common.actions"),
        accessor: '_id',
        className: "actions-cell",
        maxWidth: 90,
        filterable: false,
        sortable: false,
        Cell: cell =>
        <Button
            color="primary"
            href={`${setting?.eshop?.uri}/ticket/view/?uuid=${cell.value}`}
            target="_blank"
        >
            <i className="fa fa-external-link"></i>
        </Button>
    }];

    return (
        <div className="TicketsList">
            <PageTitle icon="th" title={title} />
            <TicketsFilters
                screenings={screenings}
                tickettypes={tickettypes}
                emails={emails}
                onSubmit={submit}
                isLoading={isLoading}
            />
            {(preparedTickets?.length > 0 && columns?.length > 0) &&
                <Table
                    id="ticketsTable"
                    data={preparedTickets}
                    columns={columns}
                    columnsSortOrder={["firstname"]}
                />
            }
        </div>
    );
}

export default TicketsList
