import React from 'react';
import { useTranslation } from 'react-i18next';
import { Utils } from '../../services';
import SelectField from '../SelectField/SelectField';

import './AdditionnalClassesField.css';

const AdditionnalClassesField = ({ name }) => {
    const { t } = useTranslation();

    const options = Utils.additionnalClasses().map(r => {
        return {
            key: r,
            text:
                <label className="m-0">
                    <i className={`fa fa-square fa-border ${r}`}></i>
                    <span className="text-dark">&nbsp;{t("common." + r)}</span>
                </label>,
            value: r !== "none" ? r : ""
        };
    });

    return <SelectField className="w-100 AdditionnalClassesField" name={ name } options={ options }/>
}

export default AdditionnalClassesField;
