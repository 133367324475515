import React, { useState } from 'react';
import { Card, CardHeader, CardBody, CardText, CardFooter, Collapse, Badge, Row, Col, UncontrolledTooltip } from 'reactstrap';
import './Draggable.css';
import { CopyButton } from '..';
import { SortableHandle } from 'react-sortable-hoc';
import { Link } from "react-router-dom";

const Draggable = ({ id, onCollapse, collapsed, title, badge, tags, cardbody, actions, draggable, link, toolTip, rightContent }) => {
    const [collapse, setCollapse] = useState(collapsed);

    const toggle = (id) => {
        onCollapse(id);
        setCollapse(!collapse);
    }

    const DragHandle = SortableHandle(() => draggable ? <i className="drag-handle fa fa-arrows"></i> : <i />);

    return (
        <Card className='Draggable'>
            <CardHeader tag={link && Link} to={link && link} id={`draggable-header-${id}`} className={`position-relative btn text-left border-0 cardBtnHeader ${(onCollapse !== undefined || link !== undefined) && "hoverable"}`} onClick={() => (onCollapse !== undefined) && toggle(id)}>
                <Row className='mb-0'>
                    <Col sm={8} className="pb-0">
                        <h4 className="m-0">
                            {title && title}
                        </h4>
                    </Col>
                    <Col sm={4}>
                        <Row>
                            <Col sm={draggable ? 11 : 12}>
                                <div className='float-right header-rules d-flex row justify-end gap-1'>
                                    {tags && (
                                        <div className="d-flex gap-1 wrap justify-end">
                                            { tags.map(t => (
                                                <CopyButton size="xs" badge color="dark" textToCopy= { t.name } />
                                            ))}
                                        </div>
                                    )}
                                    {badge && (
                                        <CopyButton size="sm" badge color="info" textToCopy= { badge } />
                                    )}
                                    { rightContent }
                                </div>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                {draggable !== false && <DragHandle className="drag-handle" />}
            </CardHeader>
            {toolTip &&
                <UncontrolledTooltip target={`draggable-header-${id}`}>{toolTip}</UncontrolledTooltip>
            }
            {(onCollapse != undefined || !cardbody) ?
                <Collapse isOpen={collapse}>
                    <CardBody>
                        <CardText tag="div">
                            {(collapse && cardbody) &&
                                cardbody}
                        </CardText>
                    </CardBody>
                </Collapse>
                :
                <CardBody>
                    <CardText tag="div">
                        {cardbody &&
                            cardbody}
                    </CardText>
                </CardBody>
            }
            <CardFooter className="text-right">
                {actions &&
                    actions}
            </CardFooter>
        </Card>
    );
}

export default Draggable;
